import { AttrField, OrmModel, PrimaryKey } from 'vuex-orm-decorators';
import { BaseModel } from '@/models/internal';

@OrmModel('configurations')
export class Configuration extends BaseModel {
  /**
   * Model name (used for CASL subject detection)
   */
  public static get modelName() {
    return 'Configuration';
  }

  /**
   * Unique identifier for this User on the API.
   */
  @PrimaryKey()
  @AttrField()
  public id!: number;

  /**
   * Unique email string for the User.
   */
  @AttrField()
  public email!: string;
}

export default Configuration;
