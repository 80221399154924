import { RouterBreadcrumb } from '@/lib/types/RouterBreadcrumb.type';
import { RouteConfig } from 'vue-router/types/router';

const config: Array<RouteConfig> = [
  {
    path: 'connections',
    component: () => import(/* webpackChunkName: "Customers" */ '@/views/Dashboard/ConnectionRequests/ConnectionRequests.vue'),
    meta: {
      requiresAuth: true,
      breadcrumbs: [{
        text: 'Connections',
        to: { name: 'connections-list' },
      }] as RouterBreadcrumb[],
    },
    children: [
      {
        path: '',
        redirect: 'list',
      },
      {
        path: 'list',
        name: 'connections-list',
        component: () => import(/* webpackChunkName: "Customers" */ '@/views/Dashboard/ConnectionRequests/ConnectionRequestsList.vue'),
        meta: {
          title: 'List Connections',
          subtitle: 'Browse through all the accessible connections requests that exist within our system.',
        },
      },
      // {
      //   path: 'create/',
      //   name: 'connections-create',
      //   component: () => import(/* webpackChunkName: "Customers" */ '@/views/Dashboard/Customers/CustomersCreate.vue'),
      //   meta: {
      //     breadcrumbs: [
      //       {
      //         text: 'Create',
      //         disabled: true,
      //       },
      //     ] as RouterBreadcrumb[],
      //     title: 'Create a Site',
      //     subtitle: `
      //       Create a Site record and save it.
      //       <span class="font-weight-bold">All fields are required.</span>
      //     `,
      //   },
      // },
      {
        path: ':id',
        name: 'connections-view',
        component: () => import(/* webpackChunkName: "Customers" */ '@/views/Dashboard/ConnectionRequests/ConnectionRequestsView.vue'),
        props: (route) => ({
          id: route.params.id,
        }),
        beforeEnter: (to, _from, next) => {
          if (to.params.id) return next();
          throw Error('Missing `id` parameter');
        },
        meta: {
          title: 'View Connection',
          subtitle: 'Review the details and data associated with this connection\'s record.',
          breadcrumbs(this: Vue): RouterBreadcrumb[] {
            return [{
              text: this.$route.params.id,
              to: { name: 'connections-view', params: { id: this.$route.params.id } },
            }];
          },
        },
      },
      // {
      //   path: ':id/edit',
      //   name: 'connections-edit',
      //   component: () => import(/* webpackChunkName: "Customers" */ '@/views/Dashboard/ConnectionRequests/ConnectionRequestsEdit.vue'),
      //   props: (route) => ({
      //     id: route.params.id,
      //   }),
      //   beforeEnter: (to, _from, next) => {
      //     if (to.params.id) return next();
      //     throw Error('Missing `id` parameter');
      //   },
      //   meta: {
      //     title: 'Edit Site',
      //     subtitle: 'Modify the details and data associated with this site\'s record.',
      //     breadcrumbs(this: Vue): RouterBreadcrumb[] {
      //       return [{
      //         text: 'Edit',
      //         to: { name: 'connections-edit', params: { id: this.$route.params.id } },
      //       }];
      //     },
      //   },
      // },
    ],
  },
];

export default config;
