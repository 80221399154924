import { RouterBreadcrumb } from '@/lib/types/RouterBreadcrumb.type';
import { RouteConfig } from 'vue-router/types/router';
import auth from './auth';
import brands from './brands';
import collections from './collections';
import customers from './customers';
import docs from './docs';
import home from './home';
import orders from './orders';
import products from './products';
import reports from './reports';
import settings from './settings';
import users from './users';
import connections from './connections';

const config: Array<RouteConfig> = [
  {
    path: '/',
    redirect: '/dashboard/login',
  },
  {
    path: '/dashboard',
    component: () => import(/* webpackChunkName: "Dashboard" */ '@/views/Dashboard/Dashboard.vue'),
    meta: {
      feature: 'dashboard',
      breadcrumbs(this: Vue): RouterBreadcrumb[] {
        return [
          {
            text: 'Dashboard',
            to: { name: 'home' },
          },
        ];
      },
    },
    children: [
      ...auth,
      ...brands,
      ...collections,
      ...customers,
      ...docs,
      ...home,
      ...orders,
      ...products,
      ...reports,
      ...settings,
      ...users,
      ...connections,
    ],
  },
];

export default config;
