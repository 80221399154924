import { ModelService } from './ModelService';
import { ConnectionRequest } from '@/models/internal';
import { IFindArguments, IFindOneArguments, ICreateArguments } from '@/lib/interfaces';
// import { UserService } from '@/services/UserService';

/**
 * Service class for interacting with the ConnectionRequest model
 */
export class ConnectionRequestService extends ModelService<typeof ConnectionRequest> {
  /**
   * Cached instance of the service
   */
  private static instance: ConnectionRequestService | null = null;

  protected model = ConnectionRequest;

  protected path = '/connection_requests';

  // private userService: UserService = UserService.getInstance();

  /**
   * Constructor
   */
  private constructor() {
    super();
  }

  /**
   * Get an instance of the ConnectionRequestService
   */
  public static getInstance() {
    if (!this.instance) {
      this.instance = new ConnectionRequestService();
      return this.instance;
    }
    return this.instance;
  }

  public get api() {
    return {
      /**
       * Create a ConnectionRequest on the server via POST request
       */
      create: async (args: IConnectionRequestCreateArguments) => {
        const { data } = await this.apiService.post(this.path, args);
        return data;
      },

      /**
       * Find one ConnectionRequest on the server via GET request
       */
      findOne: async (args: IFindOneArguments) => {
        const { data } = await this.apiService.get(`${this.path}/${args.id}`, args);
        return data;
      },

      /**
       * Find a list of ConnectionRequest on the server via GET request
       */
      find: async (args: IConnectionRequestFindArguments): Promise<any> => {
        const { data } = await this.apiService.get(this.path, args);
        return {
          connection_requests: data.connection_requests,
          total: data.total,
        };
      },

      /**
       * Update an existing ConnectionRequest on the server via PUT request
       */
      update: async () => {
        // const { data } = await this.apiService.patch(`${this.path}/${args.id}`, args);
        // return data;
        throw Error('This method has no implementation yet');
      },

      /**
       * Delete an existing ConnectionRequest on the server via DELETE request
       */
      destroy: async () => {
        throw Error('This method has no implementation yet');
      },

      /**
       * Approve an existing ConnectionRequest on the server via a custom request
       */
      approve: async (args: any) => {
        throw Error('This method has no implementation yet' + args);
      },

      /**
       * Deny an existing ConnectionRequest on the server via a custom request
       */
      deny: async (args: any) => {
        throw Error('This method has no implementation yet' + args);
      },

      /**
       * Download connect requests
       */
      download: async (args: any) => {
        throw Error('This method has no implementation yet' + args);
      },
    };
  }
}

export interface IConnectionRequestFindArguments extends IFindArguments {
  page?: number | string;
  per_page?: number | string;
  title_containing?: string;
}

interface IConnectionRequestCreateArguments extends ICreateArguments {
  connection_request: {
    brand_id?: number | string;
    site_id: number | string;
  }
}
