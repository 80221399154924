import { Customer } from '@/models/Customer';
import { ModelService } from '@/services/ModelService';
import {
  ICreateArguments,
  IUpdateArguments,
  IFindArguments,
  IFindOneArguments,
  IDestroyArguments,
} from '@/lib/interfaces';
import { ResponseType } from 'axios';
import { prepareData } from '@/models/internal';
import { CustomerProductService } from './CustomerProductService';

/**
 * Service class for interacting with the Customer model
 */
export class CustomerService extends ModelService<typeof Customer> {
  /**
   * Cached instance of the service
   */
  private static instance: CustomerService | null = null;

  protected model = Customer;

  protected path = '/customers';

  private constructor() {
    super();
  }

  /**
   * Get an instance of the CustomerService
   */
  public static getInstance() {
    if (!this.instance) {
      this.instance = new CustomerService();
      return this.instance;
    }
    return this.instance;
  }

  /**
   * Function for mapping data during the `prepareData` phase of record
   * creation/updating
   *
   * If this method isn't defined, it no longer get's used in the pipeline
   *
   * @param data
   */
  public static mapData(data: any) {
    if (data.Customer) data = data.Customer;

    const source_references = (!data.source_references)
      ? data
      : data.source_references;

    return {
      id: parseInt(data.id),
      newid: source_references.newid,
      address: source_references.address,
      city: source_references.city,
      contact_email: source_references.email,
      contact_phone: source_references.phone,
      contact: source_references.contact,
      country: source_references.country,
      approval_status: source_references.custom1,
      site_requester: source_references.custom2,
      name: source_references.name,
      phone: source_references.phone,
      postal_zip: source_references.postal_zip,
      prov_state: source_references.prov_state,
      dist_channel: source_references.dist_channel,
      cost_center: source_references.cost_centre,
      bt_address: source_references.bt_address,
      bt_city: source_references.bt_city,
      bt_contact: source_references.bt_contact,
      bt_country: source_references.bt_country,
      bt_email: source_references.bt_email,
      bt_fax: source_references.bt_fax,
      bt_name: source_references.bt_name,
      bt_phone: source_references.bt_phone,
      bt_postal_zip: source_references.bt_postal_zip,
      bt_prov_state: source_references.bt_prov_state,
      bt_web: source_references.bt_web,
      btsame: source_references.btsame,
      on_credit_hold: source_references.on_credit_hold,
      general_notes: source_references.general_notes,
      meta: data.meta,
      customer_products: data.customer_products ?
        prepareData(data.customer_products, CustomerProductService.mapData) :
        [],
    };
  }

  public get api() {
    enum ErrorMessage {
      NoCustomerError = 'Missing \'customer\' param',
      NoSiteRequesterError = 'Customer site_requester missing',
      ApproveNonPendingCustomerError = 'Cannot approve customer that is not in pending status',
      DenyNonPendingCustomerError = 'Cannot deny customer that is not in pending status',
    }

    return {
      /**
       * Find a list of unique distribution channels that Customers belong to
       */
      distributionChannels: async (args: IFindArguments) => {
        const { data } = await this.apiService.get(`${this.path}/distribution_channels`, args);
        return data;
      },

      /**
       * Create a Customer on the server via POST request
       */
      create: async (args: ICreateArguments) => {
        if (!args.customer) throw Error(ErrorMessage.NoCustomerError);

        const path = `${this.path}/create_destination`;

        return this.apiService.post(path, {
          ...args,
          customer: {
            ...args.customer,
            approval_status: 'approved',
          },
        });
      },

      /**
       * Create a Customer on the server via POST request
       */
      request: async (args: ICreateArguments) => {
        if (!args.customer) throw Error(ErrorMessage.NoCustomerError);
        if (!args.customer.site_requester) throw Error(ErrorMessage.NoSiteRequesterError);

        const path = `${this.path}/create_destination`;

        return this.apiService.post(path, {
          ...args,
          customer: {
            ...args.customer,
            site_requester: args.customer.site_requester,
            approval_status: 'pending',
          },
        });
      },

      /**
       * Find one Customer on the server via GET request
       */
      findOne: async (args: ICustomerFindOneArguments) => {
        const { data } = await this.apiService.get(`${this.path}/${args.id}`, args);
        return data;
      },

      /**
       * Find a list of Customers on the server via GET request
       */
      find: async (args: ICustomerFindArguments) => {
        const { data } = await this.apiService.get(`${this.path}`, args);
        return {
          customers: data.customers,
          total: data.total,
        };
      },

      /**
       * Get a list of Collections and nested Products for this Customer
       */
      findCollectionsProducts: async (args: IFindOneArguments) => {
        const { data } = await this.apiService.get(`${this.path}/${args.id}/collections/products`, args);
        return data;
      },

      /**
       * Find a list of Customers on the server via GET request
       */
      findDestination: async (args: IFindArguments) => {
        const { data } = await this.apiService.get(`${this.path}/index_destination`, args);
        return data;
      },

      /**
       * Update an existing Customer on the server via PATCH request
       */
      update: async (args: IUpdateArguments) => this.apiService.patch(`${this.path}/${args.id}`, args),

      /**
       * Delete an existing Customer on the server via DELETE request
       */
      destroy: async (args: IDestroyArguments) => this.apiService.delete(`${this.path}/destroy_destination`, args),

      /**
       * Approve a pending Customer on the server via PATCH
       */
      approve: async (args: IUpdateArguments) => {
        if (!args.customer) throw Error(ErrorMessage.NoCustomerError);
        if (args.customer.approval_status !== 'pending')
          throw Error(ErrorMessage.ApproveNonPendingCustomerError);

        return this.apiService.patch(`${this.path}/update_destination`, {
          ...args,
          customer: {
            ...args.customer,
            approval_status: 'approved',
          },
        });
      },

      /**
       * Deny a pending Customer on the server via PATCH
       */
      deny: async (args: IUpdateArguments) => {
        if (!args.customer) throw Error(ErrorMessage.NoCustomerError);
        if (args.customer.approval_status !== 'pending')
          throw Error(ErrorMessage.DenyNonPendingCustomerError);

        return this.apiService.patch(`${this.path}/${args.id}`, {
          ...args,
          customer: {
            ...args.customer,
            approval_status: 'denied',
          },
        });
      },

      /**
       * Fetch small list of suggestions
       */
      autocomplete: async (args: IAutocompleteArguments) => {
        const { data } = await this.apiService.get(`${this.path}/suggestions`, args);
        return data;
      },

      /**
       * Sync latest Customers data to dashboard
       */
      sync: async (args: any) => {
        return await this.apiService.post(`${this.path}/sync`, args);
      },

      /**
       * Get a list of Users that have been assigned the Site
       */
      orderFormUsers: async (args: IFindOneArguments) => {
        const { data } = await this.apiService.get(`${this.path}/${args.id}/order_form_users`, args);
        return data;
      },

      /**
       * Download CSV data listing Users
       */
      download: async (args: IDownloadCustomersArguments) => {
        const { data } = await this.apiService.get(`${this.path}/download`, args, {
          responseType: args.response_type ?? 'blob',
        });
        return data;
      },

      /**
       * Update Customer's "on_credit_hold" status (including at destination)
       */
      updateOnCreditHold: async (args: IUpdateCustomerOnCreditHoldArguments) => {
        const { data } = await this.apiService.patch(`${this.path}/${args.id}/update_on_credit_hold`, args);
        return data;
      },
    };
  }
}

export interface ICustomerFindOneArguments extends IFindOneArguments {
  include_products?: boolean;
}

export interface ICustomerFindArguments extends IFindArguments {
  page?: number | string;
  per_page?: number | string;
  name_containing?: string;
  dist_channels?: string[];
  show_on_credit_hold?: boolean;
  connectable?: boolean;
}

export interface IAutocompleteArguments extends IFindArguments {
  // Filter for name
  name_containing: string | null;
  // Number of suggestions to return (defaults to 15)
  num_suggestions?: number;
}

/**
 * Download Users CSV arguments
 */
interface IDownloadCustomersArguments extends IFindArguments {
  /**
   * Response type header
   */
  response_type?: ResponseType;
}

interface IUpdateCustomerOnCreditHoldArguments extends IFindOneArguments {
  on_credit_hold: boolean;
}
