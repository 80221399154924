

















































import { Vue, Component, Watch, Prop, Ref } from 'vue-property-decorator';
import { AuthService, UserService, BrandService } from '@/services';
import { Brand, Collection, Comment, Customer, Fulfillment, Order, OrderItem, Product } from '@/models/internal';
import { Sync } from 'vuex-pathify';
import { Utility } from '@/tools/Utility';
import UserIcon from './User/UserIcon.vue';

@Component({
  name: 'AppBar',
  components: {
    UserIcon,
  },
})
export default class AppBar extends Vue {
  @Sync('layout/appBarHeight')
  protected appBarHeight!: number;

  @Prop({ required: true, default: false })
  private readonly value!: boolean;

  @Ref('bar')
  private readonly barRef!: Vue;

  protected drawer = this.value;

  protected selectedBrandId: any = null;

  protected brands: Brand[] = [];

  private readonly userService: UserService = UserService.getInstance();

  private brandService: BrandService = BrandService.getInstance();

  private authService: AuthService = AuthService.getInstance();

  protected get user() {
    return this.userService.getActive();
  }

  protected get height(): number {
    switch (this.$vuetify.breakpoint.name) {
      case 'sm':
      case 'xs':
        return 48;
      default:
        return 56;
    }
  }

  @Watch('value')
  protected watchValue(v: boolean) {
    this.drawer = v;
  }

  public async mounted() {
    window.addEventListener('resize', this.onResize);
    this.$nextTick(this.onResize);
    this.brands = await this.fetchData();

    const storedBrandId = localStorage.getItem('selectedBrandId');
    if (storedBrandId) {
      this.selectedBrandId = Number(storedBrandId);
      localStorage.removeItem('selectedBrandId');
    }
  }

  public destroyed() {
    window.removeEventListener('resize', this.onResize);
  }

  protected onToggleDrawer(isOpen: boolean) {
    this.$emit('input', isOpen);
  }

  protected getSelectedBrand(id: number) {
    return this.brands.find((brand) => brand.id === id) || null;
  }

  protected async onChangeBrand() {
    localStorage.setItem('selectedBrandId', String(this.selectedBrandId));

    let brand = await this.getSelectedBrand(this.selectedBrandId);

    if (brand) {
      let user = brand.order_form_endpoint_user;
      await this.authService.setActiveBrand(user);

      // Clear veux store
      await Utility.clearCache(
        [
          Collection,
          Comment,
          Customer,
          Fulfillment,
          Order,
          OrderItem,
          Product,
        ],
        true, // Pass `true` to reload the page after clearing
      );
    }
  }

  private onResize() {
    this.appBarHeight = this.barRef.$el.getBoundingClientRect().height;
  }

  private async fetchData() {
    const user = this.userService.getActive();
    const params = {
      id: user && user.id,
      authentication_token: this.userService.getActiveToken(),
      show_details: true,
    };

    const response = await this.userService.api.getBrands(params);

    if (!response) throw Error('Unable to fetch a list of brands');

    return response.brands;
  }
}
