import { BaseModel } from '@/models/internal';
import { AttrField, OrmModel, PrimaryKey } from 'vuex-orm-decorators';

export interface IConnectionRequest {
  id: number;
  requested_brand_id: number;
  customer_id: number;
  order_form_endpoint_user_id: number;
  status: string;
  created_at: string; // ISO date format
  updated_at: string; // ISO date format
}

@OrmModel('connection_requests')
export class ConnectionRequest extends BaseModel implements IConnectionRequest {
  /**
   * Model name (used for CASL subject detection)
   */
  public static get modelName() {
    return 'ConnectionRequest';
  }

  @PrimaryKey()
  @AttrField()
  public id!: number;

  @AttrField()
  public requested_brand_id!: number;

  @AttrField()
  public customer_id!: number;

  @AttrField()
  public order_form_endpoint_user_id!: number;

  @AttrField()
  public status!: string;

  @AttrField()
  public created_at!: string;

  @AttrField()
  public updated_at!: string;
}

export default ConnectionRequest;
