import URL from 'url-parse';
import Axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';
import { IRequestArguments } from '@/lib/interfaces';
import { Logger } from '@/tools/Logger';
import { SentryService } from '@/services/SentryService';

/**
 * Service to house logic for sending requests to the API
 */
export class ApiService {
  /**
     * Cached instance of the service
     */
  private static instance: ApiService | null = null;

  /**
     * Base URL for requests to the API
     */
  public readonly endpoint: URL;

  /**
   * Request client
   */
  private readonly client: AxiosInstance;

  private readonly logger: Logger = new Logger({ context: 'ApiService' });

  private readonly sentryService = SentryService.getInstance();

  /**
   * Constructor
   * @param endpoint
   */
  private constructor(endpoint: string) {
    const url = new URL(endpoint);

    if (url.protocol === null) {
      throw new Error('Invalid API endpoint provided');
    }

    this.endpoint = url;

    this.client = Axios.create({
      baseURL: this.endpoint.href,
      responseType: 'json',
    });
  }

  /**
   * Get the singleton instance of this class
   */
  public static getInstance(endpoint?: string) {
    endpoint = endpoint ?? process.env.VUE_APP_API_ENDPOINT;

    if (!endpoint) {
      throw Error('Unable to initialize instance of `ApiService` without endpoint. \
      Provide a parameter or ensure your environment variables are set correctly');
    }

    if (!this.instance) {
      this.instance = new ApiService(endpoint);
      return this.instance;
    }
    return this.instance;
  }

  /**
   * Post request.
   *
   * @param path
   * @param {object} params
   * @throws {Error}
   * @returns {object} Response body
   */
  public async post<T = any>(path: string, params: IRequestArguments, options: AxiosRequestConfig = {}) {
    const config: AxiosRequestConfig = {
      responseType: 'json',
      ...options,
      method: 'POST',
      url: path,
    };
    this.logger.request({ ...config, params });
    try {
      const response = await this.client.post<T>(path, params, config);
      this.logger.response(response);
      return response;
    } catch (error) {
      this.reportError(error);
      throw error;
    }
  }

  /**
   * Get request.
   *
   * @param path
   * @param params
   */
  public async get<T = any>(path: string, params: IRequestArguments, options: AxiosRequestConfig = {}) {
    const config: AxiosRequestConfig = {
      params,
      responseType: 'json',
      ...options,
      method: 'GET',
      url: path,
    };
    this.logger.request(config);
    try {
      const response = await this.client.get<T>(path, config);
      this.logger.response(response);
      return response;
    } catch (error) {
      this.reportError(error);
      throw error;
    }
  }

  /**
   * Put request.
   *
   * @deprecated
   *
   * @param path
   * @param params
   */
  public async put<T = any>(path: string, params: IRequestArguments, options: AxiosRequestConfig = {}) {
    this.logger.warn('ApiService: Use of the `PUT` protocol is deprecated - use `PATCH` instead.');

    const config: AxiosRequestConfig = {
      responseType: 'json',
      ...options,
      method: 'PUT',
      url: path,
    };
    this.logger.request({ ...config, params });
    try {
      const response = await this.client.put<T>(path, params, config);
      this.logger.response(response);
      return response;
    } catch (error) {
      this.reportError(error);
      throw error;
    }
  }

  /**
   * Patch request.
   *
   * @param path
   * @param params
   */
  public async patch<T = any>(path: string, params: IRequestArguments, options: AxiosRequestConfig = {}) {
    const config: AxiosRequestConfig = {
      responseType: 'json',
      ...options,
      method: 'PATCH',
      url: path,
    };
    this.logger.request({ ...config, params });
    try {
      const response = await this.client.patch<T>(path, params, config);
      this.logger.response(response);
      return response;
    } catch (error) {
      this.reportError(error);
      throw error;
    }
  }

  /**
   * Delete request.
   *
   * @param path
   * @param params
   */
  public async delete<T = any>(path: string, params: IRequestArguments, options: AxiosRequestConfig = {}) {
    const config: AxiosRequestConfig = {
      params,
      responseType: 'json',
      ...options,
      method: 'DELETE',
      url: path,
    };
    this.logger.request(config);
    try {
      const response = await this.client.delete<T>(path, config);
      this.logger.response(response);
      return response;
    } catch (error) {
      this.reportError(error);
      throw error;
    }
  }

  /**
   * Report an error which occured during a request. Log the error
   * to the console (if environment supports) and capture a Sentry
   * exception (if enabled).
   * @param error Handled exception
   * @param config Configuration object for the request
   */
  private reportError(error: AxiosError): void {
    if (process.env.VUE_APP_ENABLE_SENTRY !== 'true') {
      return;
    }
    try {
      const exception = this.sentryService.captureException(error);
      this.logger.error(exception);
    } catch (error) {
      this.logger.error('Something went wrong while capturing a Sentry error');
    }
  }
}
