import { ModelService, AuthService } from '.';
import { Configuration } from '@/models/internal';
import { IApiService } from '@/lib/interfaces';

export class ConfigurationService extends ModelService<typeof Configuration> {
  /**
   * Cached instance of the service
   */
  private static instance: ConfigurationService | null = null;

  protected model = Configuration;

  protected path = '/configuration';

  private readonly authService: AuthService = AuthService.getInstance();

  /**
   * Constructor
   */
  private constructor() {
    super();
  }

  /**
   * Get an instance of the ConfigurationService
   */
  public static getInstance() {
    if (!this.instance) {
      this.instance = new ConfigurationService();
      return this.instance;
    }
    return this.instance;
  }

  public get api(): IApiService {
    return {
      /**
       * Find a list of Users on the server via GET request
       */
      find: async (args) => {
        const { data } = await this.apiService.get(this.path, args);
        return data;
      },

      /**
       * Create a Configuration on the server via POST request
       */
      create: async () => {
        throw Error('This method has no implementation yet');
      },

      /**
       * Find one Configuration on the server via GET request
       */
      findOne: async (args) => {
        const { data } = await this.apiService.get(`${this.path}`, args);
        return data;
      },

      /**
       * Update an existing Configuration on the server via PATCH request
       */
      update: async (args) => {
        const { data } = await this.apiService.patch(`${this.path}`, args);
        return data;
      },

      /**
       * Delete an existing Configuration on the server via DELETE request
       */
      destroy: async () => {
        throw Error('This method has no implementation yet');
      },
    };
  }
}
